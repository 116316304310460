<template>
  <div class="about-us">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content box" id="content">
      <div class="user-title">
        <span>技术介绍</span>
      </div>
      <ul class="news-list">
        <li class="a-ub"
          v-for="(news, index) in newsList" :key="index">
          <div class="a-ub-f1">
            <router-link class="a-e1" :to="'/newsinfo?aid=' + news.id">{{news.title}}</router-link>
          </div>
          <span>{{news.create_time}}</span>
        </li>
      </ul>
      <more-list :page="page" :more="more" :nodata="nodata" @moreCallback="moreCallback"></more-list>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import FooterNav from '@/components/FooterNav'
import MoreList from '@/components/MoreList'

export default {
  name: 'aboutus',
  data () {
    return {
      current: 'news',
      newsList: [],
      keywords: '',
      page: 1,
      count: 10,
      more: false,
      nodata: false
    }
  },
  methods: {
    getList: function () {
      let params = {
        keywords: this.keywords,
        sort: this.sort,
        page: 1,
        limit: 10
      }
      this.$post('index/articleList', params).then(res => {
        if (res.code === 1) {
          if (!res.data.total) {
            this.nodata = true
            return false
          } else {
            this.nodata = false
          }
          this.newsList = res.data.data
          if (res.data.data.length < res.data.per_page) {
            this.more = false
          } else {
            this.more = true
          }
        } else {
          this.$layer.msg(res.msg)
          this.nodata = true
        }
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
        this.nodata = true
      })
    },
    moreCallback: function () {
      let params = {
        keywords: this.keywords,
        sort: this.sort,
        page: ++this.page,
        limit: 10
      }
      this.$post('index/articleList', params).then(res => {
        if (res.code === 1) {
          for (let i in res.data.data) {
            this.newsList.push(res.data.data[i])
          }
          if (res.data.data.length < res.data.per_page) {
            this.more = false
          } else {
            this.more = true
          }
        } else {
          this.$layer.msg(res.msg)
          this.more = false
        }
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
      })
    }
  },
  mounted: function () {
    var div = document.getElementById('content')
    var h = document.documentElement.clientHeight || document.body.clientHeight
    div.style.minHeight = h - 160 + 'px'
  },
  components: {
    HeaderNav,
    FooterNav,
    MoreList
  },
  created () {
    if (localStorage.getItem('keywords')) {
      this.keywords = localStorage.getItem('keywords')
    }
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.content{
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 36px;
  padding: 0 60px 90px 60px;
}
.user-title {
  color: #4c4c4c;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  padding: 0 40px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.news-list{
  width: 860px;
  margin: auto;
}
.news-list li{
  font-size: 16px;
  line-height: 40px;
  position: relative;
  padding-left: 30px;
}
.news-list li span{
  color: #999;
  padding-left: 40px;
}
.news-list li a{
  color: #3b3b3b;
}
.news-list li:hover .a-ub-f1{
  text-decoration: underline;
}
.news-list li:after{
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #083875;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
</style>
