<!--
* 列表状态
* 无数据：is_nodata: false
* 无更多： is_more: false
-->
<template>
  <div class="more-list">
    <div class="no-data icon-nodata"
      :class="{ hide: !nodata }"></div>
    <div class="more a-ta-c"
      :class="{ 'hide': nodata, 'icon-more': more }"
      @click="moreList">
      <span class="more-btn" v-if="more">查看更多</span>
      <span v-else>没有更多了</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'more-list',
  props: {
    'page': {
      type: Number,
      default: 1
    },
    'more': {
      type: Boolean,
      default: false
    },
    'nodata': {
      type: Boolean,
      default: false
    }
  },
  methods: {
    moreList: function () {
      if (this.more) {
        this.$emit('moreCallback')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.more{
  width: 120px;
  margin: auto;
  color: #999;
  font-size: 16px;
  line-height: 40px;
  padding: 20px 0;
  background-repeat: no-repeat;
  background-position: center bottom 16px;
  background-size: auto 10px;
  cursor: pointer;
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.icon-more:hover{
  background-position: center bottom 12px;
}
.icon-more:hover .more-btn{
  color: #58dcc6;
}
.no-data{
  height: 400px;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
